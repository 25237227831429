.carousel-root {
    max-width: 900px;
    margin: 0 auto;
}
.carousel .slide {
    display: flex;
    justify-content: center;
}

a,
a:-webkit-any-link,
a:visited {
    color: inherit;
}

html, body {
    overflow-x: hidden;
    max-width: 100%;
}